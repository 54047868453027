import { Fragment } from 'react';
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames';
import AppMain from './Layout/AppMain';
import { useSelector } from 'react-redux';

const App = () => {
  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = useSelector((state) => state.themeOptions);

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <Fragment>
          <div
            className={cx(
              'app-container app-theme-' + colorScheme,
              { 'fixed-header': enableFixedHeader },
              { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
              { 'fixed-footer': enableFixedFooter },
              { 'closed-sidebar': enableClosedSidebar || width < 1250 },
              {
                'closed-sidebar-mobile': closedSmallerSidebar || width < 1250,
              },
              { 'sidebar-mobile-open': enableMobileMenu },
              { 'body-tabs-shadow-btn': enablePageTabsAlt }
            )}
          >
            <AppMain />
          </div>
        </Fragment>
      )}
    />
  );
};

export default App;
