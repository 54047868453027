export const MainNav = [
  {
    icon: 'pe-7s-map-2',
    label: 'Dashboard',
    to: '#',
  },
];

export const AccountingNav = [
  {
    icon: 'pe-7s-cash',
    label: 'Banking',
    content: [
      {
        label: 'Accounts',
        to: '#',
      },
      {
        label: 'Reconcile',
        to: '#',
      },
    ],
  },
  {
    icon: 'pe-7s-ticket',
    label: 'Receivables',
    content: [
      {
        label: 'Sales',
        to: '#',
      },
      {
        label: 'Invoices',
        to: '#',
      },
      {
        label: 'Deposits',
        to: '#',
      },
    ],
  },
  {
    icon: 'pe-7s-news-paper',
    label: 'Payables',
    content: [
      {
        label: 'Bills',
        to: '#',
      },
      {
        label: 'Approvals',
        to: '#',
      },
      {
        label: 'Payments',
        to: '#',
      },
    ],
  },
  {
    icon: 'pe-7s-users',
    label: 'Contacts',
    content: [
      {
        label: 'Vendors',
        to: '#',
      },
      {
        label: 'Customers',
        to: '#',
      },
    ],
  },
];

export const ReportsNav = [
  {
    icon: 'pe-7s-graph2',
    label: 'Performance',
    to: '#'
  },
  {
    icon: 'pe-7s-copy-file',
    label: 'Financials',
    to: '#'
  },
];

export const DocumentsNav = [
  {
    icon: 'pe-7s-folder',
    label: 'Files',
    to: '#'
  },
  {
    icon: 'pe-7s-notebook',
    label: 'Library',
    to: '#'
  },
];

export const AdminNav = [
  {
    icon: 'pe-7s-id',
    label: 'Profile',
    to: '#'
  },
  {
    icon: 'pe-7s-config',
    label: 'Settings',
    to: '#'
  },
];
