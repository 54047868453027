import { Fragment, useState } from 'react';
import {
  Nav,
  Button,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from 'reactstrap';
import calculator from '../../../assets/utils/images/calculator.png';

const CalculatorIconButton = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleHandler = () => setDropdownOpen((prevState) => !prevState);
  const closeDropdownHandler = () => setDropdownOpen(false);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleHandler}>
      {/*  <UncontrolledDropdown> */}
      <DropdownToggle i className="p-0 me-2" color="link">
        <div className="header-icon">
          <i className="pe-7s-keypad header-calculator-icon" />
        </div>
      </DropdownToggle>
      {/* <DropdownMenu className="rm-pointers dropdown-menu-lg"> */}
      <DropdownMenu className="rm-pointers dropdown-menu">
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <img
            // style={{ width: '206px', height: '328px', margin: '0 auto' }}
            style={{ width: '250px', height: '398px', margin: '0 auto' }}
            src={calculator}
            alt="calculator"
          />
        </div>
        <Nav horizontal style={{ margin: '0 auto', width: 'fit-content' }}>
          <NavItem className="nav-item-btn text-center">
            <Button size="md" className="btn-shadow" color="info">
              Copy
            </Button>
          </NavItem>
          <NavItem className="nav-item-btn text-center">
            <Button size="md" className="btn-shadow" color="danger" onClick={() => closeDropdownHandler()}>
              Close
            </Button>
          </NavItem>
        </Nav>
      </DropdownMenu>
      {/* </UncontrolledDropdown> */}
    </Dropdown>
  );
};
export default CalculatorIconButton;
