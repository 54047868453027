import {
  Nav,
  NavLink,
  Col,
  Row,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { IoIosBookmark } from 'react-icons/io';

const MegaMenu = () => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle className="p-0" color="link">
        <div className="header-icon">
          <IoIosBookmark color="rgba(255, 255, 255, 0.9)" fontSize="20px" />
        </div>
      </DropdownToggle>
      <DropdownMenu className="rm-pointers dropdown-menu-lg">
        <div className="dropdown-mega-menu">
          <div className="grid-menu grid-menu-3col">
            <Row className="g-0">
              <Col xl="4" lg="4">
                <Nav vertical>
                  <NavItem className="nav-item-header">Payables</NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <i className="nav-link-icon lnr-inbox"> </i>
                      <span>New Bill</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <i className="nav-link-icon lnr-book"> </i>
                      <span>New Payment</span>
                      {/* <div className="ms-auto badge rounded-pill bg-danger">
                          5
                        </div> */}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <i className="nav-link-icon lnr-picture"> </i>
                      <span>A/P Inbox</span>
                      <div className="ms-auto badge rounded-pill bg-danger">
                        3
                      </div>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                      <NavLink disabled href="#">
                        <i className="nav-link-icon lnr-file-empty"> </i>
                        <span>Dashboards</span>
                      </NavLink>
                    </NavItem> */}
                </Nav>
              </Col>
              <Col xl="4" lg="4">
                <Nav vertical>
                  <NavItem className="nav-item-header">Receivables</NavItem>
                  <NavItem>
                    <NavLink href="#">New Invoice</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      New Deposit
                      {/* <div className="ms-auto badge bg-success">New</div> */}
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                      <NavLink href="#">Users &amp; Groups</NavLink>
                    </NavItem> */}
                  {/* <NavItem>
                      <NavLink href="#">Proprieties</NavLink>
                    </NavItem> */}
                </Nav>
              </Col>
              <Col xl="4" lg="4">
                <Nav vertical>
                  <NavItem className="nav-item-header">Administrative</NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <span>Reconcile</span>
                      <div className="ms-auto badge rounded-pill bg-danger">
                        5
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">New Contact</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">Upload Files</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </div>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
export default MegaMenu;
