import CalculatorIconButton from './CalculatorIconButton';
import MegaMenu from './MegaMenu';

const HeaderDots = () => {
  return (
    <div className="header-dots">
      <CalculatorIconButton />
      <MegaMenu />
    </div>
  );
};
export default HeaderDots;
