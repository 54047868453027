import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import HeaderLogo from '../AppLogo';
import UserBox from './Components/UserBox';
import SearchBox from './Components/SeachBox';
import HeaderDots from './Components/HeaderDots';

const AppHeader = () => {
  const {
    // enableHeaderShadow,
    // closedSmallerSidebar,
    // headerBackgroundColor,
    enableMobileMenuSmall,
  } = useSelector((state) => state.themeOptions);

  return (
    <TransitionGroup>
      <CSSTransition
        component="div"
        className={cx(
          'app-header',
          'bg-night-sky',
          'header-shadow',
          'header-text-light'
          //   headerBackgroundColor, {
          //     'header-shadow': enableHeaderShadow,
          // }
        )}
        appear={true}
        timeout={1500}
        enter={false}
        exit={false}
      >
        <div>
          <HeaderLogo />
          <div
            className={cx('app-header__content', {
              'header-mobile-open': enableMobileMenuSmall,
            })}
          >
            <div className="app-header-left">
              <SearchBox />
            </div>
            <div className="app-header-right">
              <HeaderDots />
              <UserBox />
            </div>
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};
export default AppHeader;
