import React from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import logo from '../assets/utils/images/carto_logo_fullcolor.png';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const submitHandler = () => {
    navigate('/main');
  }
 
  return (
    <div className="h-100 bg-plum-plate-second">
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Col md="8" sm="10" xs="10" className="mx-auto app-login-box">
          {/* <div className="app-logo-inverse mx-auto mb-3" /> col-md-8 col-*/}
          <div className="modal-dialog w-100 mx-auto">
            <div className="modal-content">
              <div className="modal-body">
                <img className='d-block mx-auto mb-3 w-50 h-50' src={logo} alt="logo" />
                <div className="h5 modal-title text-center">
                  <h4 className="mt-2">
                    <div>Welcome back,</div>
                    <span>Please sign in to your account below.</span>
                  </h4>
                </div>
                <Form>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          type="email"
                          name="email"
                          id="exampleEmail"
                          placeholder="Email here..."
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          type="password"
                          name="password"
                          id="examplePassword"
                          placeholder="Password here..."
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup check>
                    <Input type="checkbox" name="check" id="exampleCheck" />
                    <Label for="exampleCheck" check>
                      Keep me logged in
                    </Label>
                  </FormGroup>
                </Form>
                <div className="divider" />
                <h6 className="mb-0">
                  No account?{' '}
                  <a
                    href="https://colorlib.com/"
                    onClick={(e) => e.preventDefault()}
                    className="text-primary"
                  >
                    Sign up now
                  </a>
                </h6>
              </div>
              <div className="modal-footer clearfix">
                <div className="float-start">
                  <a
                    href="https://colorlib.com/"
                    onClick={(e) => e.preventDefault()}
                    className="btn-lg btn btn-link"
                  >
                    Recover Password
                  </a>
                </div>
                <div className="float-end">
                  <Button color="info" size="lg" onClick={submitHandler}>
                    Login to Dashboard
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-center text-white opacity-8 mt-3">
            Copyright &copy; ArchitectUI 2019
          </div> */}
        </Col>
      </div>
    </div>
  );
};
export default Login;
