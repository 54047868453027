import React, { Fragment } from 'react';
import cx from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector, useDispatch } from 'react-redux';
import { setEnableMobileMenu } from '../redux/actions/themeOptionsAction';
import HeaderLogo from './AppLogo';
import AppNav from './AppNav';

const AppSidebar = () => {
  const dispatch = useDispatch();

  const {
    enableBackgroundImage,
    // enableSidebarShadow,
    enableMobileMenu,
    // backgroundColor,
    backgroundImage,
    backgroundImageOpacity,
  } = useSelector((state) => state.themeOptions);

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <TransitionGroup>
        <CSSTransition
          component="div"
          className={cx(
            'app-sidebar',
            'bg-dark',
            'sidebar-text-light'
            //   backgroundColor, {
            //   'sidebar-shadow': enableSidebarShadow,
            // }
          )}
          appear={true}
          enter={false}
          exit={false}
          timeout={500}
        >
          <div>
            <HeaderLogo />
            <PerfectScrollbar>
              <div className="app-sidebar__inner">
                <AppNav />
              </div>
            </PerfectScrollbar>
            <div
              className={cx('app-sidebar-bg', backgroundImageOpacity)}
              style={{
                backgroundImage: enableBackgroundImage
                  ? 'url(' + backgroundImage + ')'
                  : null,
              }}
            ></div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
};
export default AppSidebar;
