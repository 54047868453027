import React, { useState } from 'react';
import cx from 'classnames';

const SearchBox = () => {
  const [activeSearch, setActiveSearch] = useState(false);
  return (
    <div
      className={cx('search-wrapper', {
        active: activeSearch,
      })}
    >
      <div className="input-holder">
        <input
          type="text"
          className="search-input"
          placeholder="Type to search"
        />
        <button
          onClick={() => setActiveSearch(!activeSearch)}
          className="search-icon"
        >
          <span />
        </button>
      </div>
      <button onClick={() => setActiveSearch(false)} className="btn-close" />
    </div>
  );
};
export default SearchBox;
