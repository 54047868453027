import React, { Fragment } from 'react';
// import Loader from 'react-loaders';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainPage from '../pages/Main';
import DashboardPage from '../pages/Dashboards';
import LoginPage from '../pages/Login';

const AppMain = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/main" element={<MainPage />} />
        <Route path="/dashboards" element={<DashboardPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route exact path="/" element={<Navigate to="/login"/>}/>
      </Routes>
      <ToastContainer />
    </Fragment>
  );
};
export default AppMain;
