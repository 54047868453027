import React, { Fragment, useState } from 'react';
import { Slider } from 'react-burgers';
import AppMobileMenu from './AppMobileMenu';
import { useSelector, useDispatch } from 'react-redux';
import { setEnableClosedSidebar } from '../redux/actions/themeOptionsAction';
import logo from '../assets/utils/images/carto_logo.png';

const AppLogo = () => {
  const [active, setActive] = useState(false);
  // const [mobile, setMobile] = useState(false);
  // const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] =
  //useState(false);

  const dispatch = useDispatch();

  const {
    enableClosedSidebar,
    // enableMobileMenu,
    // enableMobileMenuSmall
  } = useSelector((state) => state.themeOptions);

  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableClosedSidebar(!enableClosedSidebar));
  };
  return (
    <Fragment>
      <div className="app-header__logo">
        {/* <div className="logo-src" />  */}
        <img className="logo-src" src={logo} alt="logo" />
        <div className="header__pane ms-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Slider
              width={26}
              lineHeight={2}
              lineSpacing={5}
              color="#6c757d"
              active={active}
              onClick={() => setActive(!active)}
            />
          </div>
        </div>
      </div>
      <AppMobileMenu />
    </Fragment>
  );
};
export default AppLogo;
