import React, { Fragment } from 'react';
import AppHeader from '../Layout/AppHeader';
import AppSidebar from '../Layout/AppSidebar';

const MainPage = () => {
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner"></div>
          <p>Main Page</p>
          {/* <AppFooter /> */}
        </div>
      </div>
    </Fragment>
  );
};
export default MainPage;
