import { Fragment } from 'react';
import {
  MainNav,
  AccountingNav,
  ReportsNav,
  DocumentsNav,
  AdminNav,
} from './NavItems';
import MetisMenu from 'react-metismenu';
import { useSelector, useDispatch } from 'react-redux';
import { setEnableMobileMenu } from '../../redux/actions/themeOptionsAction';

const Nav = () => {
  const dispatch = useDispatch();
  const enableMobileMenu = useSelector((state) => state.themeOptions);

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };
  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Menu</h5>
      <MetisMenu
        content={MainNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <h5 className="app-sidebar__heading">Accounting</h5>
      <MetisMenu
        content={AccountingNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <h5 className="app-sidebar__heading">Reports</h5>
      <MetisMenu
        content={ReportsNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <h5 className="app-sidebar__heading">Documents</h5>
      <MetisMenu
        content={DocumentsNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <h5 className="app-sidebar__heading">Admin</h5>
      <MetisMenu
        content={AdminNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
    </Fragment>
  );
};
export default Nav;
